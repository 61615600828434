<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption"
                >新增</el-button
              >
            </el-form-item>
            <el-form-item style="margin-left: 1vw; margin-top: 0.5vw">
              <el-date-picker
                v-model="dayTime"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="" style="margin-left: 2vw">
                <el-input v-model="searchName" placeholder="请输入名称"></el-input>
              </el-form-item> -->
          </el-form>
        </div>
        <el-button
          style="margin-left: 1vw"
          type="success"
          size="small"
          @click="search"
          >搜索</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        align="center"
        :index="indexMethod"
        width="100"
        type="index"
      ></el-table-column>
<!--      <el-table-column
        prop="harvestNumber"
        label="采收批次号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>-->
      <el-table-column
        prop="plantingNumber"
        label="批次"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recoveryPrincipalName"
        label="采收负责人"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recoveryPrincipalTel"
        label="负责人电话"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="harvestYield"
        label="采收数量"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="harvestYieldUnit"
        label="数量单位"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recoveryTime"
        label="采收时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="plantBatchState"
        label="批次是否结束"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.plantBatchState == 0">未结束</span>
          <span v-if="scope.row.plantBatchState == 1">已结束</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="备注"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="processState"
        label="二维码"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
            <div class="operation">
                <img src="@/assets/sourceScreen/codeImg.png" alt="" @click="lookCode(scope.row)">
            </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="7vw !important"
        >
          <!-- <el-form-item label="产品名称" prop="productName">
            <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
            <el-input
              :disabled="editMse"
              v-model="addForm.productName"
              placeholder="请输入产品名称"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="批次" prop="plantingId">
            <el-select
              :disabled="editMse"
              v-model="addForm.plantingId"
              placeholder="请选择批次"
              @change="plantChange"
            >
              <el-option
                v-for="self in harvestData"
                :key="self.id"
                :label="self.batchNumber"
                :value="self.id"
              ></el-option>
            </el-select>
            <!-- <el-input v-model="addForm.harvestNumber" placeholder="请输入作物类型"></el-input> -->
          </el-form-item>
          <el-form-item label="采收负责人" prop="recoveryPrincipalName">
            <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
            <el-input
              :disabled="editMse"
              v-model="addForm.recoveryPrincipalName"
              placeholder="请输入采收负责人"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人电话" prop="recoveryPrincipalTel">
            <el-input
              :disabled="editMse"
              v-model="addForm.recoveryPrincipalTel"
              placeholder="请输入负责人电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="采收数量" prop="harvestYield">
            <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
            <el-input
              :disabled="editMse"
              v-model="addForm.harvestYield"
              placeholder="请输入采收数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="采收数量单位" prop="harvestYieldUnit">
            <el-input
              v-model="addForm.harvestYieldUnit"
              placeholder="请输入采收数量单位"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="采收时间" prop="recoveryTime">
            <el-date-picker v-model="addForm.recoveryTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </el-form-item> -->

          <el-form-item label="采收时间" prop="recoveryTime">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="addForm.recoveryTime"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="批次是否结束" prop="plantBatchState">
            <el-radio-group
              v-model="addForm.plantBatchState"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="结束时间"
            prop="endTime"
            v-show="addForm.plantBatchState == 1"
          >
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="addForm.endTime"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="content">
            <el-input
              v-model="addForm.content"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="isUpload"
            label="现场图片"
            prop="picture"
            v-show="editMse == false"
          >
            <el-upload
              action="/imgUpdata"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              list-type="picture-card"
              :file-list="cImgfileList"
              :headers='{
                token
              }'
            >
              <!-- <img
                    v-if="addForm.imgUrl"
                    :src="addForm.imgUrl"
                    class="businessImg avatar"
                /> -->
              <i class="el-icon-plus"></i>
              
            </el-upload>
          </el-form-item>
          <el-form-item label="上传监测报告">
            <el-upload
              class="upload-demo"
              action="/imgUpdata"
              :headers='{
                token
              }'
              :limit="1"
              :file-list="fileList"
              :on-change="handleChange"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccess"
              :on-remove="onRemove"
            >
              <div class="upFlieBox">
                <i class="el-icon-upload"></i><span>点击上传</span>
              </div>
            </el-upload>
          </el-form-item>
          <!-- productImg -->
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 二维码 -->
    <el-dialog :modal="false" title="二维码" :visible.sync="codeShow" width="20%">
        <div v-loading="loadingCode" id="qrcode" ref="qrCodeUrl"></div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary"
            >下载</el-button
            >
        </span> -->
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  // name: 'sensorManagement',
  data() {
    return {
      token: '',
      tableData: [],
      dayTime: [],
      page: 0,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: "",
      addForm: {},
      rules: {
        plantingId: [
          {
            required: true,
            message: "批次不可为空",
            trigger: ["blur", "change"],
          },
        ],
        recoveryPrincipalName: [
          {
            required: true,
            message: "采收负责人不可为空",
            trigger: ["blur", "change"],
          },
        ],
        recoveryPrincipalTel: [
          {
            required: false,
            message: "负责人电话不可为空",
            trigger: ["blur", "change"],
          },
        ],
        harvestYield: [
          {
            required: true,
            message: "采收数量不可为空",
            trigger: ["blur", "change"],
          },
        ],
        harvestYieldUnit: [
          {
            required: true,
            message: "采收数量单位不可为空",
            trigger: ["blur", "change"],
          },
        ],
        recoveryTime: [
          {
            required: true,
            message: "采收时间不可为空",
            trigger: ["blur", "change"],
          },
        ],
        plantBatchState: [
          {
            required: true,
            message: "批次是否结束不可为空",
            trigger: ["blur", "change"],
          },
        ],
        content: [
          {
            required: false,
            message: "备注不可为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      // value: true,
      pageSize: 10,
      pageNow: 1,
      cImgfileList: [],
      urlList: [],
      harvestData: [],
      editMse: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      fileList: [],
      codeShow: false,
      loadingCode: true,
      sourCodeUrl: "http://119.188.246.129:20023/#/h5",
      imgUrl: '',
    };
  },
  mounted() {
    //   this.getTime();
    this.token = JSON.parse(window.localStorage.getItem("token"));
    this.getList();
    this.getHarvestData();
  },
  methods: {
    // 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    getTime() {
      var today = new Date();
      var year = today.getFullYear();
      var dateArr = [];
      for (var i = 0; i < 7; i++) {
        var newDate = new Date(today.getTime() - i * 1000 * 60 * 60 * 24);
        var month =
          parseInt(newDate.getMonth()) + 1 > 9
            ? parseInt(newDate.getMonth()) + 1
            : "0" + (parseInt(newDate.getMonth()) + 1);
        var day =
          newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate();
        var fullDate = `${year}-${month}-${day}`;
        if (i == 6) {
          dateArr.push(fullDate);
        }
        if (i == 0) {
          dateArr.push(fullDate);
        }
      }
      this.dayTime = dateArr.reverse();
    },

    //请求列表数据
    getList(startTime, endTime) {
      this.$get("/harvestManagement/getHarvestManagements", {
        page: this.page,
        size: this.size,
        plantingNumber: this.searchName,
        startTime: startTime || "",
        endTime: endTime || "",
      }).then((res) => {
        //console.log(res);
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData);
        }
      });
    },
    //   获取批次列表
    getHarvestData() {
      this.$get("/plantingBatch/getPlantingBatchList", {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == "success") {
          this.harvestData = res.data.datas;
          //console.log(this.harvestData);
        }
      });
    },
    plantChange(val){
        //console.log(val);
        this.addForm.plantingNumber = this.harvestData.find((v) => v.id == val).batchNumber;
        this.addForm.baseId = this.harvestData.find((v) => v.id == val).baseId;
    },
    // 编辑
    edits(row) {
      //console.log(row);
      this.showFlag = true;
      this.editMse = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.addForm = JSON.parse(JSON.stringify(row));
      let img = this.addForm.picture;
      this.$getBlob("/fileops/show", {
        fileName: img,
      }).then((res) => {
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
        //console.log(fileData);
        let blob = new Blob([res.data],{type: 'image/'+ fileData})
        let url = window.URL.createObjectURL(blob);
        this.addForm.picture = url;
        this.imgUrl = img;
      })
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/productWarehousing/delProductWarehousing/${id}`).then(
            (res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            }
          );
        })
        .catch((e) => e);
    },
    // 确认提交
    saveForm(formName) {
      // let id = this.ids;
      this.addForm.id = this.ids;
      this.addForm.picture = this.imgUrl;
      this.addForm.createUser = window.localStorage.getItem("user");
      let url = "/harvestManagement/addHarvestManagement";
      if (this.formTitle == "新增") {
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, this.addForm).then((res) => {
            if (res.data.state == "success") {
              if (this.formTitle == "新增") {
                this.$message.success("添加成功");
              } else {
                this.$message.success("修改成功");
              }
              this.messageBox = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg);
              this.messageBox = false;
            }
          });
        } else {
          // //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.editMse = false;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },

    lookCode(data) {
        let url = this.sourCodeUrl + `?active=sixth&id=${data.plantBatch}`;
        this.urls = url;
        this.$nextTick(() => {
            this.codeShow = true;
            setTimeout(() => {
                this.$refs.qrCodeUrl.innerHTML = "";
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: url,
                    width: 130,
                    height: 130,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H
                });
            }, 200);
        });
    },


    // 搜索
    search() {
      this.page = 1;
      let time = [];
      let startTime
      let endTime
      if (this.dayTime) {
        time = this.dayTime;
        let time1 = " 00:00:00";
        startTime = time[0] + time1;
        endTime = time[0] + time1;
        this.getList(startTime, endTime);
      } else {
        this.getList();
      }
      
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },

    beforeAvatarUpload2(val) {
      const fileLimitSize = 2; // 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false;
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false;
      }

      return true;
    },
    handleAvatarSuccess2(res, file) {
      let imgName = res.data;
      this.$getBlob("/fileops/show", {
        fileName: res.data,
      }).then((res) => {
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
        let blob = new Blob([res.data],{type: 'image/'+ fileData})
        let url = window.URL.createObjectURL(blob);
        this.addForm.picture = url;
        this.imgUrl = imgName;
      })
    },

    // 上传检测报告
    handleChange(file, fileList) {
        this.fileList = fileList.slice(-1);
    },
    beforeAvatarUpload(file) {
        let {
            name
        } = file;
        name = name.split(".")[1];
        if (name == "jpg" || name == "png") {
            // this.uploadState = 0;
            return true;
        }
        this.$message.info("请检查上传格式！");
        this.fileList = [];
        return false;
    },
    handleExceed() {
        this.$message.info("只能上传一个文件");
    },
    handleAvatarSuccess(res, file) {
      //console.log(res, file);
        this.addForm.detectionImg = res.data;
        //   //console.log(this.fileList);
        // this.$getBlob("/fileops/show", {
        //   fileName: res.data,
        // }).then((res) => {
        //   let fileNames = res.config.params.fileName;
        //   let fileData = fileNames.split(".")[1];
        //   let blob = new Blob([res.data],{type: 'image/'+ fileData})
        //   let url = window.URL.createObjectURL(blob);
        //   this.addForm.detectionImg = imgName;
        // })
    },
    onRemove(file, fileList) {
        this.addFormModel.detectionImg = "";
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
.operation{
    display: flex;
    justify-content: space-around;
}
.operation img{
    cursor: pointer;
}
#qrcode /deep/ img {
    margin: 0 auto;
}
</style>
